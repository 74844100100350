import cn from "classnames";
export const styles = {
  container:
    "pt-hyam-navbar-h-sm xl:pt-hyam-navbar-h-xl pb-24",
  content: "hyam-cols",
  button_container: "col-span-full mx-auto",
  headline: cn(
    "hyam-text-h1",
    "mx-auto w-fit",
    " py-16 xl:py-20",
    "underline decoration-hyam-primary-600 underline-offset-[1.5rem]",
  ),
  message: cn(
    "hyam-text-h5 mx-auto mb-6 text-center",
    "col-span-full col-start-1 md:col-span-4 md:col-start-2 xl:col-span-4 xl:col-start-5",
  ),
};

export type HyamPage404Styles = typeof styles;
