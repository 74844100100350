import { withConfigure } from "hyam-core/utils/withConfigure";
import { styles as moduleStyles, HyamPage404Styles } from "./styles";
import { FormattedMessage } from "react-intl";
import { HyamPrismicButton, HyamPrismicButtonProps } from "hyam-prismic/components";
import ArrowLeft from "hyam-core/svg/arrow-left-24px.svg";

export type HyamPage404Props = {
  styles?: Partial<HyamPage404Styles>;
  stylesReplace?: Partial<HyamPage404Styles>;
  buttonProps?: Partial<HyamPrismicButtonProps>;
};

export const _HyamPage404 = (props: HyamPage404Props) => {
  const styles = props.styles as HyamPage404Styles;
  const { buttonProps } = props;

  return (
    <div className={styles.container}>
      <h1 className={styles.headline}>404</h1>
      <div className={styles.content}>
        <p className={styles.message}>
          <FormattedMessage id="page_404_error_message" />
        </p>
        <div className={styles.button_container}>
          <HyamPrismicButton
            href="/"
            rightIcon={null}
            leftIcon={<ArrowLeft />}
            {...buttonProps}
          >
            <FormattedMessage id="page_404_button_label" />
          </HyamPrismicButton>
        </div>
      </div>
    </div>
  );
};

export const HyamPage404 = withConfigure<HyamPage404Props, HyamPage404Styles>({
  componentName: "HyamPage404",
  Component: _HyamPage404,
  moduleStyles,
});
